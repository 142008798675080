#form-file-upload {
    height: auto;
    width: 100%;
    text-align: center;
    position: relative;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.input-file-upload {
    display: none;
}

#label-file-upload {
    height: 100%;
    width: 100%;
    min-height: 16rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px dashed #e8e8e8;
    background: rgba(10, 140, 255, 0.07);
}

#label-file-upload.drag-active {
    background: rgba(10, 140, 255, 0.04);
}

#drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}